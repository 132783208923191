import React from "react";
import style from "./nav-bar.module.css";

import ClearIcon from "@mui/icons-material/Clear";
import ReplyIcon from "@mui/icons-material/Reply";
import LogoutIcon from '@mui/icons-material/Logout';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { useNavigate, useParams } from "react-router-dom";

function NavBar({ menu, navOpen }) {
  const { username } = useParams(); 
  const navigate = useNavigate();

  const handleCloseNav = () =>{
    navOpen(!menu);
  }

  const handleBackHistory = () => {
    window.history.back();
  }

  const handleExit = () => {
    navigate("/")
  }

  const handleGoToDashboard = () => {
    navigate("/home")
  }
  
  const handleGoToRegister = () => {
    navigate("/register")
  }

  return (
    <div
      className={`${
        menu ? style["navbar-body-open"] : style["navbar-body-close"]
      } `}
    >
      <div className={style["close-button"]} >
        <ClearIcon className={style["ClearIcon"]} onClick={handleCloseNav}/>
      </div>

      <button className={style["project-body"]} onClick={handleBackHistory}>
        <ReplyIcon className={style["ClearIcon"]}/>
        Voltar
      </button>
      <hr></hr>
      <button className={style["project-body"]} onClick={handleGoToDashboard}>
        <AssignmentIcon className={style["ClearIcon"]}/>
        Dashboard
      </button>
      <hr></hr>
      <button className={style["project-body"]} onClick={handleGoToRegister}>
        <LeaderboardIcon className={style["ClearIcon"]}/>
        Registro de Pragas
      </button>
      <hr></hr>
      <button className={style["project-body"]} onClick={handleExit}>
        <LogoutIcon className={style["ClearIcon"]} />
        Sair</button>
      <hr></hr>
    </div>
  );
}

export default NavBar;
