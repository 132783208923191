import React, { useEffect, useState } from "react";
import style from "./beetle-lague.module.css";

import CaterPillarIamge from "../../../images/beetle-colored.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import TotalInformations from "./total-informations/total-informations";

function BeetlePlague({ plague, infestation, data }) {
  const [samples, setSamples] = useState([]);
  const [unsheet, setUnsheet] = useState();

  const [levelInfestation, setLevelInfestation] = useState("");

  useEffect(() => {
    const amostra = Array.from({ length: 5 }).map((_, index) => ({
      planta: index + 1,
      percentualDesfolha: 0,
    }));
    setSamples(amostra);
  }, []);


  const handleChangeUnsheet = (index, value) => {
    const updatedAmostras = [...samples];
    updatedAmostras[index].percentualDesfolha = value;
    setSamples(updatedAmostras);
  };

  const handleChangeInfestation = (information) => {
    let info = information;

    infestation(info);

  }

  return (
    <div className={style["beetle-plague"]}>
      <div className={style["card"]}>
        <div className={style["title-card"]}>Amostras</div>
        <div className={style["sample-plague"]}>
          <img src={CaterPillarIamge} />
          <div className={style["name-sample-plague"]}>
            <div>{plague.name}</div>
            <div>{plague.scientific}</div>
          </div>
        </div>

        <div className={style["samples"]}>
          {samples.map((sample, index) => (
            <div className={style["collected-sample-title"]}>
              <div className={style["collected-sample-plant"]}>
                <div className={style["collected-sample-plant-number"]}>
                  {index + 1}
                </div>
                <div className={style["collected-sample-plant-text"]}>
                  Planta
                </div>
              </div>

              <div className={style["information-unsheet"]}>
                <div> % de Desfolha </div>
                <div>
                    <input value={sample.percentualDesfolha} className={style["input"]} type="number" onChange={(e) => handleChangeUnsheet(index, e.target.value)}></input>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <TotalInformations samples={samples} infestation={handleChangeInfestation}  data = {(value) => data(value)}/>

    </div>
  );
}

export default BeetlePlague;
