// Rotas.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from "../pages/signin/signin"
import Dashboard from '../pages/Dashboard/dashboard';
import Register from '../pages/Register/register';

function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/register" element={<Register/>}/>
      </Routes>
    </Router>
  );
}

export default Rotas;