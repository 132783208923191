import React from "react";
import style from "./balls.module.css";

export function RedBalls() {
  return (
    <div className={style["balls"]}>
      <div className={style["Redballs"]}></div>
      <span></span>
      <div className={style["Redballs"]}></div>
      <span></span>
      <div className={style["Redballs"]}></div>
    </div>
  );
}

export function YellowBalls() {
  return (
    <div className={style["balls"]}>
      <div className={style["Yellowballs"]}></div>
      <span></span>
      <div className={style["Yellowballs"]}></div>
      <span></span>
      <div className={style["Grayballs"]}></div>
    </div>
  );
}

export function GreenBalls() {
  return (
    <div className={style["balls"]}>
      <div className={style["Greenballs"]}></div>
      <span></span>
      <div className={style["Grayballs"]}></div>
      <span></span>
      <div className={style["Grayballs"]}></div>
    </div>
  );
}


export function RedBall() {
  return (
    <div className={style["balls"]}>
      <div className={style["Redballs"]}></div>
    </div>
  );
}

export function YellowBall() {
  return (
    <div className={style["balls"]}>
      <div className={style["Yellowballs"]}></div>
    </div>
  );
}

export function GreenBall() {
  return (
    <div className={style["balls"]}>
      <div className={style["Greenballs"]}></div>
    </div>
  );
}


