import React, { useEffect, useState } from "react";
import style from "./total-informations.module.css";

function TotalInformations({ samples, infestation, data }) {
  const [percentual, setPercentual] = useState(0);

  const [levelInfestation, setLevelInfestation] = useState("");

  function totalMediaFunc() {
    let unsheet = 0;
    samples.map((sample) => {
      unsheet += parseInt(sample.percentualDesfolha);
    });

    const percentual = unsheet / 5;

    if(isNaN(percentual) ){

      setPercentual(0.0);
    }else{

      setPercentual(percentual.toFixed(1));
    }

    if (percentual <= 0 || isNaN(percentual)) {
      setLevelInfestation("-");
      infestation({ diagnostic: "Baixo", prob: parseFloat(percentual) });
    } else if (percentual < 5.1) {
      setLevelInfestation("Baixo");
      infestation({ diagnostic: "Baixo", prob: parseFloat(percentual) });
    } else if (percentual >= 5.1 && percentual < 29.1) {
      setLevelInfestation("Médio");
      infestation({ diagnostic: "Médio", prob: parseFloat(percentual) });
    } else {
      setLevelInfestation("Alto");
      infestation({ diagnostic: "Alto", prob: parseFloat(percentual) });
    }
  }

  useEffect(() => {
    totalMediaFunc();
    data(samples)
  }, [samples]);

  return (
    <div>
      <div className={style["card"]}>
        <div className={style["group-informations"]}>
          <div className={style["total-percentual"]}>
            <div>Percentual Total de Desfolha</div>
            <p>{percentual}%</p>
          </div>
        </div>
      </div>
      <div className={style["card"]}>
        <div className={style["title-card"]}>Nível de Infestação</div>
        <div className={style["level-infestation"]}>
          <div
            style={
              percentual > 0.01 && percentual < 5.1
                ? { backgroundColor: "#358128" }
                : percentual >= 5.1 && percentual < 29.1
                ? { backgroundColor: "#FEC601" }
                : percentual >= 29.1
                ? { backgroundColor: "red" }
                : { backgroundColor: "#393939" }
            }
            className={style["status"]}
          >
            {levelInfestation}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalInformations;
