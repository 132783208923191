import React, { useEffect, useState } from "react";
import style from "./data-basic.module.css";

import CreateIcon from "@mui/icons-material/Create";
import LockIcon from "@mui/icons-material/Lock";
import { Recycling } from "@mui/icons-material";

function DataBasic({ dataBasic }) {
  const [inputEdit, setInputEdit] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")).user);
  const [region, setRegion] = useState(localStorage.getItem("region"));
  const [date, setDate] = useState("");
  const [faseForest, setFaseForest] = useState("REFORME");
  const [rotation, setRotation] = useState("");
  const [ugb, setUgb] = useState(
    localStorage.getItem("region") === "RCO"
      ? "GARÇA"
      : localStorage.getItem("region") === "RNO"
      ? "CARBONITA"
      : "CACHOEIRA D'ANTA"
  );
  const [material, setMaterial] = useState("AEC 0004");


  const handleEditInformations = () => {
    setInputEdit(!inputEdit);
  };

  const handleChangeRegion = (value) => {
    setRegion(value.target.value);
  };

  const handleChangeFaseForest = (value) => {
    setFaseForest(value.target.value);
  };

  const handleChangeRotation = (value) => {
    setRotation(value.target.value);
  };

  const handleChangeUGB = (value) => {
    setUgb(value.target.value);
  };

  const handleChangeMaterial = (value) => {
    setMaterial(value.target.value);
  };

  const handleChangeDate = () => {
    let date = new Date();

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString();

    let dateComplete = `${day.padStart(2, "0")}/${month.padStart(
      2,
      "0"
    )}/${year.padStart(4, "0")}`;
    setDate(dateComplete);
  };

  function getWeekOfMonth() {
    let date = new Date();

    let dayOfMonth = date.getDate();
    let dayOfWeek = date.getDay();
    let weekOfMonth = Math.ceil((dayOfMonth + 6 - dayOfWeek) / 7);

    setRotation(weekOfMonth);
  }

  const updateInformations = () => {
    let informationsCards = {
      region: region,
      faseForest: faseForest,
      rotation: rotation,
      date: date,
      ugb: ugb,
      material: material,
      monitor: JSON.parse(localStorage.getItem("user")).user,
    };

    dataBasic(informationsCards);
  };



  useEffect(() => {
    handleChangeDate();
    getWeekOfMonth();
  }, []);

  useEffect(() => {
    updateInformations();
  }, [region, faseForest, rotation, ugb, material]);

  useEffect(() => {
    if(localStorage.getItem("register-plot-alternate")){

      let register = localStorage.getItem("register-plot-alternate");
      setUgb(JSON.parse(register).ugb);
      setMaterial(JSON.parse(register).plant);
    }
  }, [localStorage.getItem("register-plot-alternate")])

  return (
    <div className={style["card"]}>
      <div className={style["title-card"]}>Dados Básicos</div>
      <div className={style["body-card"]}>
        <div className={style["left-column-card"]}>
          <div>
            Região:
            <input
              className={
                inputEdit ? style["input-enable"] : style["input-disable"]
              }
              value={region}
              onChange={(value) => handleChangeRegion(value)}
              disabled={!inputEdit}
            ></input>
          </div>
          <div>
            Fase da Floresta:
            <input
              className={
                inputEdit ? style["input-enable"] : style["input-disable"]
              }
              value={faseForest}
              onChange={(value) => handleChangeFaseForest(value)}
              disabled={!inputEdit}
            ></input>
          </div>
          <div>
            Rotação:
            <input
              className={
                inputEdit ? style["input-enable"] : style["input-disable"]
              }
              value={rotation}
              onChange={(value) => handleChangeRotation(value)}
              disabled={!inputEdit}
            ></input>
          </div>
        </div>
        <div className={style["right-column-card"]}>
          <div>
            UGB:
            <input
              className={
                inputEdit ? style["input-enable"] : style["input-disable"]
              }
              value={ugb}
              onChange={(value) => handleChangeUGB(value)}
              disabled={!inputEdit}
              style={{
                width: "80%",
              }}
            ></input>
          </div>
          <div>
            Material Gnético:
            <input
              className={
                inputEdit ? style["input-enable"] : style["input-disable"]
              }
              value={material}
              onChange={(value) => handleChangeMaterial(value)}
              disabled={!inputEdit}
            ></input>
          </div>
        </div>
      </div>
      <div className={style["information-blocked"]}>
        <div>
          <LockIcon className={style["LockIcon"]} />
          Data:
          <input
            style={{ width: "5rem" }}
            className={style["input-disable"]}
            value={date}
            disabled={true}
          ></input>
        </div>
        <div>
          <LockIcon className={style["LockIcon"]} />
          Monitor:
          <input
            className={style["input-disable"]}
            value={user}
            disabled={true}
          ></input>
        </div>
      </div>
      <div className={style["edit-card"]}>
        <div onClick={handleEditInformations}>
          Toque aqui para alterar <CreateIcon className={style["CreateIcon"]} />
        </div>
      </div>
    </div>
  );
}

export default DataBasic;
