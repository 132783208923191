import React from "react";
import style from "./signin.module.css";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logoFlorestas from "../../images/Arcelor_Mittal.png";
import logoSipremo from "../../images/image-66.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import axios from "axios";

function Signin() {
  const [user, setUser] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [selectCamp, setSelectChamp] = React.useState("");

  const [viewPass, setViewPass] = React.useState("password");

  const [handleChangeStyle, setHandleChangeStyle] = React.useState({
    display: "none",
  });

  let userObject = JSON.stringify({ user: user, password: password });

  const navigate = useNavigate();

  const handleChangeUser = (value) => {
    setUser(value.target.value);
    userObject = JSON.stringify({ user: user, password: password });
    localStorage.setItem("user", userObject);
  };

  const handleChangePassword = (value) => {
    setPassword(value.target.value);
    userObject = JSON.stringify({ user: user, password: password });
    localStorage.setItem("user", userObject);
  };

  const handleChangeSelect = (value) => {
    setSelectChamp(value.target.value);
    userObject = JSON.stringify({ user: user, password: password });
    localStorage.setItem("user", userObject);
  };

  const notUser = () => {
    let style = {
      display: "block",
      color: "red",
      marginTop: "10px",
    };
    setHandleChangeStyle(style);
  };

  const notifyError = (text) => toast.error(text);

  const handleLogin = async (e) => {
    const login = {
      query: {
        username: user,
        password: password,
      },
    };



    if (navigator.onLine) {

      await axios.post("https://sf.sipremo.com/api/acm/plot", {query:{}}).then(({data}) =>{
        localStorage.setItem("allPlots", JSON.stringify(data));
      } )

      if (selectCamp !== "" && user !== "" && password !== "") {
        await axios
          .post(`https://sf.sipremo.com/api/acm/get_user/`, login)
          .then(({ data }) => {
            if (data.length > 0) {
              localStorage.removeItem("register-plot-alternate");
              localStorage.removeItem("user");
              localStorage.removeItem("uniqueOccurrences");
              localStorage.removeItem("occurrences-sort");
              localStorage.removeItem("region");
              localStorage.setItem("region", selectCamp);
              localStorage.setItem("user", userObject);
              navigate(`/home`);
            } else {
              notifyError("Usuário Não Encontrado");
            }
          })
          .catch((err) => {
            notifyError("Usuário Não Encontrado");
          });
      } else {
        notUser();
      }
    }

    if (!navigator.onLine) {
      let usuario = JSON.parse(localStorage.getItem("user"));

      if (
        selectCamp === localStorage.getItem("region") &&
        user === usuario.user &&
        password === usuario.password
      ) {
        localStorage.setItem("region", selectCamp);
        localStorage.setItem("user", { user: user, password: password });
        localStorage.setItem("nameUser", user);
        navigate(`/home`);
      } else {
        notifyError("Usuário Não Encontrado");
      }
    }
  };

  const viewNewPassFunc = () => {
    if (viewPass === "password") {
      setViewPass("text");
    }

    if (viewPass === "text") {
      setViewPass("password");
    }
  };

  return (
    <div className={style["main-container"]}>
      <main className={style.container}>
        <div className={style["image"]}>
          <img src={logoSipremo} className={style["image-image"]}></img>
          <img
            src={logoFlorestas}
            className={style["image-image"]}
            style={{
              marginTop: "25px",
            }}
          ></img>
        </div>

        <h2>Login</h2>
        <form>
          <div className={style["input-field"]} style={{ marginBottom: "0px" }}>
            <p>
              Usuário <e className={style["required"]}>*</e>
            </p>
            <input
              className={style["inputs-text"]}
              type="text"
              name="user"
              id="user"
              placeholder="usuário"
              value={user}
              onChange={handleChangeUser}
            ></input>
            <div className={style.underline}></div>
          </div>

          <p style={handleChangeStyle}>Campo Necessário.</p>

          <div className={style["input-field"]}>
            <p>
              Senha <e className={style["required"]}>*</e>
            </p>
            <div>
            <input
              className={style["inputs-password"]}
              type={viewPass}
              name="password"
              id="password"
              placeholder="senha"
              value={password}
              onChange={handleChangePassword}
            ></input>
            {viewPass === "password" ? (
                <VisibilityIcon
                  onClick={viewNewPassFunc}
                  className={style["icons-pass"]}
                />
              ) : (
                <VisibilityOffIcon
                  onClick={viewNewPassFunc}
                  className={style["icons-pass"]}
                />
              )}
            </div>
            
            <div className={style.underline}></div>
          </div>

          <p style={handleChangeStyle}>Campo Necessário.</p>

          <div className={style["input-field"]}>
            <p>
              Região <e className={style["required"]}>*</e>
            </p>
            <select
              name="selectCamp"
              id="selectCamp"
              value={selectCamp}
              onChange={handleChangeSelect}
            >
              <option value="">Selecione a região</option>
              <option value="RNO">RNO</option>
              <option value="RCO">RCO</option>
              <option value="RRD">RRD</option>
            </select>
          </div>

          <p style={handleChangeStyle}>campo Necessário.</p>

          <input
            className={style["button"]}
            type="button"
            value="Login"
            onClick={handleLogin}
          ></input>
        </form>

        <ToastContainer />
      </main>
    </div>
  );
}

export default Signin;
