import React, { useEffect, useState } from "react";
import style from "./area.module.css";

import CreateIcon from "@mui/icons-material/Create";

function Area({ areaInformations, validator }) {
  const [plot, setPlot] = useState("");
  const [hectar, setHectar] = useState("");

  const handleChangePlot = (value) => {
    setPlot(value.target.value);
  };

  const handleChangeHectar = (value) => {
    setHectar(value.target.value);
  };

  const handleChangeInformationsArea = () => {
    let informations = {
      plot,
      hectar,
    };

    areaInformations(informations);
  };

  useEffect(() => {
    handleChangeInformationsArea();
  }, [plot, hectar]);

  useEffect(() => {
    if (localStorage.getItem("allPlots")) {
      let register = localStorage.getItem("allPlots");

      JSON.parse(register).map((reg) => {
        if (reg.region === localStorage.getItem("region")) {
          // console.log(reg.plot);
          if (reg.plot === plot) {
            // console.log(reg)
            setHectar(reg.area);
          }
        }
      });
    }
  }, [plot]);

  useEffect(() => {
    setPlot("");
    setHectar("");
  }, [validator])

  return (
    <div className={style["card"]}>
      <div className={style["title-card"]}>Área</div>
      <div className={style["sector-inputs"]}>
        <input
          placeholder="Digite o Talhão monitorado"
          className={style["input-information"]}
          value={plot}
          onChange={(value) => handleChangePlot(value)}
        ></input>
      </div>
      <div className={style["sector-inputs"]}>
        <input
          placeholder="ha"
          className={style["input-information"]}
          value={hectar}
          onChange={(value) => handleChangeHectar(value)}
          type="number"
        ></input>
      </div>
    </div>
  );
}

export default Area;
