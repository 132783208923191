import React from "react";
import style from "./dashboard.module.css";

import Header from "../../components/header/header.js";
import NavBar from "../../components/nav-bar/nav-bar.js";
import BodyDashboard from "../../components/body-dashboard/body-dashboard.js";

function Dashboard() {
  const [menuBoard, setMenuBoard] = React.useState();

  const handleMenuBoard = (e) => {
    setMenuBoard(e);
  };

  return (
    <div className="main">
      <Header menu={handleMenuBoard}></Header>

      <div className={style["body"]}>
        <div
          className={menuBoard ? style["navbar-open"] : style["navbar-close"]}
        >
          <NavBar menu={menuBoard} navOpen={handleMenuBoard}></NavBar>
        </div>

        <div className={style["body-body"]}>
          <BodyDashboard />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
