import React from "react";
import style from "./card.module.css";
import "swiper/swiper-bundle.css";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/parallax";

import { RedBall, GreenBall, YellowBall } from "../balls/balls";

import caterpillar from "../../../images/caterpillar.png";
import moth from "../../../images/moth.png";
import psilide from "../../../images/psilide.png";
import beetle from "../../../images/beetle.png";
import leaf from "../../../images/leaf.png";

function Card({ plot }) {

  const alternator = (value) => {
    
    let sem = value.split(" ")[0];
    let numberSem = value.split(" ")[1];
    let year = value.split(" ")[3];
    let month = value.split(" ")[2];

    if(value.split(" ")[2] == "Feb."){
      month = "Fev."
    }else if(value.split(" ")[2] == "Apr."){
      month = "Abr."
    }else if (value.split(" ")[2] == "May."){
      month = "Mai."
    }else if (value.split(" ")[2] == "Aug."){
      month = "Ago."
    }else if (value.split(" ")[2] == "Sep."){
      month = "Set."
    }else if ( value.split(" ")[2] == "Oct." ){
      month = "Out."
    }else if ( value.split(" ")[2] == "Dec." ){
      month = "Dez."
    }

    return(`${sem} ${numberSem} ${month} ${year}`)
  }

  return (
    <div className={style["cards"]}>
      <div>
        {navigator.onLine ? (
          <img
            className={style["image-card-plot"]}
            src={`/images/${localStorage.getItem("region").toLocaleLowerCase()}/${plot.plot}.jpg`}
            alt="Nenhuma talhão encontrada!"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/images/no-image.jpg";
            }}
          ></img>
        ) : (
          <p className={style["image-text"]}>
            Não foi possível carregar a imagem.
            Sem conexão a internet.
          </p>
        )}
      </div>

      <div className={style["title-card"]}>{plot.municipality}</div>
      <div className={style["subtitle-card"]}>{plot.ugb}</div>
      <div className={style["plot-card"]}>Talhão {plot.plot}</div>
      <div className={style["informations-card"]}>
        <div className={style["plague-card"]}>
          <div className={style["plague-card-image"]}>
            {plot.plague ===
            ("Lagarta Parda" ||
              "Lagarta Verde" ||
              "Lagarta Cachorrinho" ||
              "Lagarta Marrom") ? (
              <img className={style["Images-plagues"]} src={caterpillar} />
            ) : plot.plague ===
              ("Glena" ||
                "Sarsina" ||
                "Dyrphia" ||
                "Nistalea" ||
                "Hylesia" ||
                "Sabulodes") ? (
              <img className={style["Images-plagues"]} src={moth} />
            ) : plot.plague ===
              ("Percevejo Bronzeado" || "Besouro Amarelo" || "Besouro Azul") ? (
              <img className={style["Images-plagues"]} src={beetle} />
            ) : (
              <img
                className={style["Images-plagues"]}
                src={psilide}
                style={{ marginBottom: "15px" }}
              />
            )}
          </div>
          <div className={style["plague-card-legend"]}>{plot.plague}</div>
        </div>
        <div>
          <div className={style["chance-infestation"]}>
            <div
              className={style["chance-infestation-prob"]}
              style={
                plot.prob <= 30.0
                  ? {
                      color: "green",
                    }
                  : plot.prob <= 50.0 && plot.prob >= 31.0
                  ? {
                      color: "#FEC601",
                    }
                  : {
                      color: "red",
                    }
              }
            >
              {plot.prob}%
            </div>
            <div className={style["chance-infestation-legend"]}>
              Chances de Infestação
            </div>
          </div>
        </div>
        <div>
          <div className={style["plant-card"]}>
            <div className={style["plant-card-image"]}>
              <img src={leaf} />
            </div>

            <div className={style["plant-legend"]}>{plot.plant}</div>
          </div>
        </div>
      </div>
      <div className={style["forcast-period"]}>
        Infestação prevista para {alternator(plot.period)}
      </div>
      <div className={style["area-hectar-card"]}>
        {Math.floor(plot.area)} Hectares Afetados
      </div>
      <div className={style["level-infestation-card"]}>Nível infestação</div>
      <div className={style["ball-card"]}>
        {plot.prob >= 15 && plot.prob <= 30 ? (
          <GreenBall />
        ) : plot.prob >= 30.1 && plot.prob <= 50 ? (
          <YellowBall />
        ) : (
          <RedBall />
        )}
      </div>
      <div
        className={style["level-infestation-card-info"]}
        style={
          plot.prob >= 15 && plot.prob <= 30
            ? {
                color: "green",
              }
            : plot.prob >= 30.1 && plot.prob <= 50
            ? {
                color: "#FEC601",
              }
            : {
                color: "red",
              }
        }
      >
        { plot.prob >= 15 && plot.prob <= 30 ? "Baixo" : plot.prob >= 30.1 && plot.prob <= 50 ? "Médio" : "Alto"}
      </div>
    </div>
  );
}

export default Card;
